<template>
  <div>
    <div class="vx-row pb-10">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        class=""
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl font-bold fontSizeMedium">
        {{ $t("YourAllSurgeriesAndTreatment") }}
      </p>
    </div>
    <div>
      <vx-card style="backgroun-color: none !important">
        <div slot="no-body" class="tabs-container pt-6 "> 
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner ">
            <vs-tab
              :label="$t('UpCommingSurgeries') + '  (' + CommingCount + ')'"
              icon-pack="feather"
              icon="icon-corner-down-right"
              @RefershGrid="getUpCammingPatientReservationSurgery"
            >
              <div class="tab-text">
                <patient-surgeies :Model="ModelComming" class="mt-4"    />
              </div>
            </vs-tab>
            <vs-tab
              :label="$t('OldSurgeries') + '  (' + OldCount + ')'"
              icon-pack="feather"
              icon="icon-corner-down-left"
              @RefershGrid="getOldPatientReservationSurgery"
            >
              <div class="tab-text">
                <patient-surgeies  :Model="ModelOld" class="mt-4"  />
              </div>
            </vs-tab>
             <vs-tab
              :label="$t('CancelledSurgeries') + '  (' + CanceledCount + ')'"
              icon-pack="feather"
              icon="icon-corner-down-left"
              @RefershGrid="getCancelPatientReservationSurgery"
            >
              <div class="tab-text">
                <patient-surgeies  :Model="ModelCanceled" class="mt-4"  />
              </div>
            </vs-tab>
        
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import PatientSurgeies from './PatientSurgeies.vue';
export default {
  data() {
    return {
      baseURL: domain,
      ModelOld: [],
      ModelComming: [],
      ModelCanceled: [],
      activeTab: 0,
      EnglishLan: false,
      OldCount: 0,
      CommingCount: 0,
      CanceledCount:0,
    };
  },
  components: {
    PatientSurgeies,
  },
  props: {
   
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
   getCancelPatientReservationSurgery(){
     debugger
      var model = {};
      model.PatientId = this.$store.state.AppActiveUser.Patient.ID;
      model.statusIds = [3];
      this.$vs.loading();
      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model).then((res)=>{
          this.CanceledCount = res.data.Data.length;
          this.ModelCanceled = res.data.Data;
          this.$vs.loading.close();
         // this.getOldPatientReservationSurgery();
        })
    
   },
    getOldPatientReservationSurgery(){
      debugger
      var model = {};
      model.PatientId = this.$store.state.AppActiveUser.Patient.ID;
      model.statusIds = [5];
      this.$vs.loading();
      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model).then((res)=>{
          this.OldCount = res.data.Data.length;
          this.ModelOld = res.data.Data;
          this.$vs.loading.close();
        })    
   },
    getUpCammingPatientReservationSurgery(){
      debugger
      var model = {};
      model.PatientId = this.$store.state.AppActiveUser.Patient.ID;
      model.StatusIds = [1,2,6,7,8];
          model.DateFrom = new Date();
          this.$vs.loading();
      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model).then((res)=>{
          this.CommingCount = res.data.Data.length;
          this.ModelComming = res.data.Data;
          this.$vs.loading.close();
        })    
   },
   
  
  },
  computed: {
    PatientReservationSurgeries() {
      return this.$store.state.patientList.PatientReservationSurgeries;
    }
  },
  created() {
    debugger;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

     if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.$store
        .commit("patientList/SET_PatientReservationSurgeries", [])
    // this.getPatientReservationSurgery(true);
    this.getUpCammingPatientReservationSurgery();
    this.getOldPatientReservationSurgery();
    this.getCancelPatientReservationSurgery();
    // this.SearchAppointment(true);
  },
  mounted()
  {
      window.onscroll = () => {
      myFunction()
    
    };

      function myFunction() {
           var navbar = document.getElementById("navbarStick");
        var sticky = navbar.offsetTop;
          if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
          } else {
            navbar.classList.remove("sticky");
          }
        }
  }
};
</script>
