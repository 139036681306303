<template>
  <div id="RSurgery" class="scroll p-3">
    <table
      style="height: 70%"
      class="text-center w-full  shadow-md bg-white">
      <tr class="HeaderTableColor">
        <th class=" text-center">
          {{ $t("Surgeries") }}
        </th>
        <th class=" text-center">
          {{ $t("PreferDateFrom") }}
        </th>
        <th class=" text-center">
          {{ $t("PreferDateTo") }}
        </th>
        <th class="text-center">
          {{ $t("SurgeryDate") }}
        </th>
          <th class=" text-center">{{ $t("PaymentStatus") }}</th>
        <th class="  text-center">{{ $t("Status") }}</th>
        <th class=" text-center">
          <!-- <div class="MyPopClose">
                <feather-icon   icon="XIcon" svgClasses="w-7 h-7 hover:text-primary stroke-current" @click.stop="$emit('closePop')" />
            </div> -->
            {{ $t("Doctor") }}</th>
      </tr>
      <tr
        class=""
        v-for="(item, index) in Model"
        :key="index"
      >
        <td>
          <div @click="GoTOSurgeryProfile(item.ID)">
         
            <p v-if="item.HospitalSurgery.Surgery">
              <u class="text-primary" style="cursor: pointer;">
               
                {{ item.HospitalSurgery.Surgery.Name }}</u
              >
            </p>
          </div>
        </td>
         <td>
          <p v-if="item.SurgeryDateFrom">
            {{ item.SurgeryDateFrom.split("T")[0] }}
          </p>
        </td>
         <td>
          <p v-if="item.SurgeryDateTo">
            {{ item.SurgeryDateTo.split("T")[0] }}
          </p>
        </td>
        <td>
          <p v-if="item.SurgeryDate">
            {{ item.SurgeryDate.split("T")[0] }}
          </p>
           <p v-else>{{$t("NotSetYet")}}</p>
        </td>
         <td>
          <p v-if="item.PaymentStatus">{{ item.PaymentStatus.Name }}</p>
        </td>
        <td>
          <p v-if="item.Status">{{ item.Status.Name }}</p>
        </td>

        <td>
          <div v-if="item.Doctor">
              <div>
                <imageLazy
                      :imageStyle="{ 'border-radius': '40px','width':'80px','height':'80px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + item.Doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
               
              </div>
              <p v-if="item.Doctor">{{ item.Doctor.FullName }}</p>
          </div>
           <p v-else>{{$t("NotSetYet")}}</p>
        </td>
      </tr>
   <tr v-if="Model.length==0">
        <td></td>
        <td><h3  class="w-full sm:w-auto text-center text-primary mt-5 mb-5">{{$t("NoDataToshow")}}</h3></td>
        <td></td>
          </tr>
    </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      baseURL: domain,
      //Model: []
    };
  },
  components:{
    imageLazy
  },

  computed: {
    // PatientReservationSurgeries() {
    //   return this.$store.state.patientList.PatientReservationSurgeries;
    // }
  },
   props: {
    Model: {
      type: Array,
      required: true,
    },

  },
  methods: {

    // getPatientReservationSurgery(id) {
    //   var model = {};
    //   model.PatientId = id;
    //   model.statusIds = [3, 5]; //confirmed
    //   this.$vs.loading();

    //   this.$store
    //     .dispatch("patientList/getPatientReservationSurgery", model)
    //     .then(() => {
    //       this.$vs.loading.close();
    //             if(this.Model==null||this.Model.length==0){
    //          this.$vs.notify({
    //       title: this.$t("NoData"),
    //       text: this.$t("NoDataToshow"),
    //       iconPack: "feather",
    //       icon: "icon-alert-circle",
    //       color: "warning"
    //     });
    //     }
    //     })
    //     .catch(() => {
    //       this.$vs.loading.close();
    //     });
    // }
    // ,
        GoTOSurgeryProfile(ID) {
      this.$router.push({ name: "Surgeryprofile", params: { ID: ID } });
    }
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // this.$store
    //     .commit("patientList/SET_PatientReservationSurgeries", [])
    // // const ID = this.$route.params.ID;
    // const ID = this.$store.state.AppActiveUser.Patient.ID;
    // if (ID != undefined) {
    //   debugger;
    //   this.getPatientReservationSurgery(ID);
    // }
  }
};
</script>
<style>


#RSurgery table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#RSurgery td,
#RSurgery th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#RSurgery th {
  border-top: none;
}

#RSurgery th:last-child {
  border-top-right-radius: 36px;
}
#RSurgery th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}


#RSurgery td:first-child {
  border-left: none;
}
#RSession th,
tr {
  height: 5rem;
}

.scroll{
   overflow-x:auto !important
}
</style>
